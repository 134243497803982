import React from "react"
import heroPoster from "../images/landing.jpg"

export default function HeroVid(props) {
    const heroVid = "https://www.youtube.com/embed/WD_gT1s3nTI"

    if (props.littleScreen === "1"){
        return (
        <video poster={heroPoster}>
        </video>
        )

    }
    else {
        return (
            <video poster={heroPoster} muted autoPlay playsInline loop>
            </video>
         )
    }
}
