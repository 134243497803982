import React from 'react'

const Details = () => (
  <>
      <div className='text-light'>
          <h3 className='pt-5'>Address</h3>
          <p><strong>Above And Beyond Perspective</strong><br></br>
              Portugal - Madeira, Calheta, 9370-256 <br/>
              VAT : PT308329678
          </p>
          <p><h3>Phone</h3> (+351) 935 303 194</p>
          <p><h3>WhatsApp</h3> (+351) 935 303 194</p>
          <h3>Email</h3><a href='mailto:contact@aboveandbeyondperspective.com'>contact@aboveandbeyondperspective.com</a>
      </div>
  </>
)
export default Details
