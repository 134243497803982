import React from "react"
import {Card} from 'react-bootstrap'
import { useState } from 'react'
import { StaticImage } from "gatsby-plugin-image"

const cardStyle = {
  backgroundColor: "transparent",
//   minWidth : "200px",
}

const cardStyleBig = {
    backgroundColor: "transparent",
    // width : "100%",
}

const bottomRight = {
    position: 'absolute',
    bottom: '-20px',
    right: '20px'
}
export default function ServiceDetails(props) {

    const [smallScreen, setSmallScreen] = useState(false)
    const aida3 = "../images/events.jpeg"
    const aida4 = "../images/wed_ring.jpg"
    const highlight2 = "../images/carousel/A1-highlight2.png"
    const highlight = "../images/carousel/A3-highlight1.png"
    return (
        <>
            <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6">
                    <Card style={smallScreen ? cardStyleBig : cardStyle}>
                        <StaticImage
                            src={highlight2}
                            alt="Highlight 2 terrain"
                            placeholder="blurred"
                            layout="constrained"
                            quality={100}
                        />
                        <Card.Body bg-transparent>
                            <Card.Title className="text-light">Real Estate</Card.Title>
                            <Card.Text>
                                <p>Giving the best viewing experience, from above, offering the best visibility so that
                                    everyone can see their future land or house from every angle.</p>
                                <div className="video-container">
                                    <iframe
                                        src="https://www.youtube.com/embed/WD_gT1s3nTI?controls=0&loop=1&autoplay=1&mute=1&playlist=WD_gT1s3nTI"
                                        title="YouTube video player" frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen></iframe>
                                </div>
                                <br/>
                                Drone pictures: 75&euro; (Excl. VAT)<br/>
                                Drone video with pictures and editing: 150&euro; (Excl. VAT)<br/>
                            </Card.Text>
                        </Card.Body>
                        <Card.Body bg-transparent>
                        </Card.Body>
                    </Card>
                    <br></br>
                </div>


                <div className="col-sm-12 col-md-6 col-lg-6">
                    <Card style={smallScreen ? cardStyleBig : cardStyle}>
                        <StaticImage
                            src={highlight}
                            alt="Highlight of the terrain"
                            placeholder="blurred"
                            layout="constrained"
                            quality={100}
                        />
                        <Card.Body bg-transparent>
                            <Card.Title className="text-light">Highlighting</Card.Title>
                            <Card.Text>
                                <p>Precision highlighting of the property helps to depict the area, using lines and floor plans, dynamically added in the video.</p>
                                <div className="video-container">
                                    <iframe
                                        src="https://www.youtube.com/embed/fzhtuSLQNH4?controls=0&loop=1&autoplay=1&mute=1&playlist=fzhtuSLQNH4"
                                        title="YouTube video player" frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen></iframe>
                                </div>
                            </Card.Text>
                        </Card.Body>
                        <Card.Body bg-transparent>
                        </Card.Body>
                    </Card>
                    <br></br>
                </div>


                <div className="col-sm-12 col-md-6 col-lg-6">
                    <Card style={smallScreen ? cardStyleBig : cardStyle}>
                        <StaticImage
                            src={highlight}
                            alt="Highlight of the terrain"
                            placeholder="blurred"
                            layout="constrained"
                            quality={100}
                        />
                        <Card.Body bg-transparent>
                            <Card.Title className="text-light">FPV : Indoor Drone Tours</Card.Title>
                            <Card.Text>
                                <p> Stand out in a competitive market with innovative marketing techniques that highlight your commitment to quality.</p>
                                <p>FPV tours provide a realistic, first-person perspective, making viewers feel as if they are physically walking through the property.</p>
                                <div className="video-container">
                                    <iframe
                                        src="https://www.youtube.com/embed/fYOCii_HGtk?controls=0&loop=1&autoplay=1&mute=1&playlist=fYOCii_HGtk"
                                        title="YouTube video player" frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen></iframe>
                                </div>
                                <br/>
                                Indoor Drone Tours: 250&euro; (Excl. VAT)<br/>
                            </Card.Text>
                        </Card.Body>
                        <Card.Body bg-transparent>
                        </Card.Body>
                    </Card>
                    <br></br>
                </div>

            </div>
        </>
    )
}

