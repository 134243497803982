import React from "react"

export default function Home(props) {
  return (
    <section>
      <div className="mt-5">
        <h2>Welcome to Above And Beyond Perspective</h2><br/>

        <p><strong>Hello, my name is Corentin Dockx</strong></p>
        <p>I am an experienced drone pilot with more than 7 years of experience in aerial photography and videography. I
          specialize in capturing stunning visuals for real estate, events, and commercial projects.</p>
        <hr></hr>
        <h4>Cinematic Techniques</h4>
        <p>Skilled in framing and composition to create visually compelling aerial shots that tell a story.</p>

        <h4>Post-Production</h4>
        <p>Experienced in editing and enhancing footage using industry-standard software to deliver polished final
          products.</p>
        <hr></hr>
        <h3>Certifications and Safety</h3>
        <h4>EASA Certification</h4>
        <p>Fully licensed by The European Union Aviation Safety Agency (EASA) to ensure safe and compliant drone
          operations.</p>

        <h4>Safety First</h4>
        <p>Committed to following all safety protocols and regulations, ensuring the protection of people and property
          during every flight.</p>

        <hr></hr>
        <h3>Projects Experience</h3>

        <h4>Real Estate</h4>
        <p>Successfully completed aerial photography and videography for numerous residential and commercial properties,
          helping clients showcase their listings effectively.</p>

        <h4>Events</h4>
        <p>Captured memorable moments from weddings, festivals, and corporate events, providing unique perspectives that
          enhance storytelling.</p>

        <h4>Custom Projects</h4>
        <p>Adapted to specific client needs, whether it’s for promotional videos, inspections, or land surveys.</p>
        <hr></hr>

      </div>
    </section>
  )
}
