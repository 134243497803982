import React from "react"
import ServiceDetails from "./serviceDetails"

const imgStyle = {
	maxHeight: '210px',
	maxWidth: '210px',
	// display: 'block',
	marginLeft: 'auto',
	marginRight: 'auto',
}

const cardStyle = {
	minHeight: '100px'
}

export default function Service(props) {
  return (
	  <>
	  	<section>
			<h1 className="pt-5">Services</h1>
				<div className="col-lg-12 col-md-12 col-sm-12">
					<section className="shadow mb-5 card bg-transparent border-0">
						<div style ={cardStyle} className="card-body">
							<ServiceDetails />
						</div>
					</section>
				</div>
		</section>
		</>
  )
}
