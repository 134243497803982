import React from 'react'

import Layout from './contact-components/layout'
import Form from './contactGB'
import Details from './contact-components/details'


const Contact = () => (
  <Layout>
    <section className='row'>
      <div className='col-lg-6 asideLeft'>
        <Form />
      </div>
      <div className='col-lg-6 asideRight'>
        <Details />
      </div>
    </section>
  </Layout>
)

export default Contact
